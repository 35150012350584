.pricing-section {
    padding: 60px 0;
    background-color: #f2e3d1;
    text-align: center;
}

.section-title, .group-title {
    margin-bottom: 30px;
    color: #2e3191;
}

.section-title h2, .group-title {
    font-size: 28px;
    font-weight: 700;
}

.pricing-group {
    margin-bottom: 50px;
}

.pricing-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.pricing-card {
    background: #ffffffaf;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    text-align: center;
    transition: transform 0.3s;
}

.pricing-card:hover {
    transform: scale(1.05);
}

.featured-card {
    border: 2px solid #e55041;
}

.popular-badge {
    background: #e55041;
    color: white;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.card-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.card-price {
    font-size: 30px;
    color: #e55041;
    margin: 15px 0;
}

.card-description {
    font-size: 16px;
    color: #555;
}

.card-btn {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 30px;
    background: #2e3191;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.card-btn-container {
    margin-top: auto;
    text-align: center;
  }

.card-btn:hover {
    background: #e55041;
    color: #fff;
}
