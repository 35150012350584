.collection {
  height: 100vh;
  display: contents;
  justify-content: center;
  align-items: center;
}

.collection .content {
  height: 30rem; /* Increased height */
  width: 58rem; /* Increased width */
  border: .2rem solid rgba(255, 255, 255, .1);
  border-radius: .7rem;
  overflow: hidden;
  position: relative; /* Added for absolute positioning of title */
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
}

.slide-background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-end; /* Align to the bottom */
}

.slide-title {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: #6c665e;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.7rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; /* Add some margin from the bottom */
  color: #f2e3d1;
}

/* Swiper styles */
.hero-swiper-container {
  width: 100%;
  height: 100%;
}

.hero-swiper-pagination {
  background-color: #f2e3d1;
}

.hero-swiper-button-next {
  color: #f2e3d1;
}

.hero-swiper-button-prev {
  color: #f2e3d1;
}