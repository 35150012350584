.roller-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100vw; 
  position: relative;
  height: 60px; /* Increased height */
  box-sizing: border-box;
  background-color: #f2e3d1;
}

.roller-text {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  will-change: transform;
  font-size: 1.5rem; /* Increased font size */
  font-weight: bold;    
}

.roller-text-item {
  font-family: 'ExtraBoldFont', sans-serif; /* Apply the extra bold font */
  font-size: 1.5rem; /* Ensure extra bold weight */
  animation: roller 10s linear infinite;
}

@keyframes roller {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}