.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2e3d1a4;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #2e3191;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #000; /* Define a cor do texto como preto */
}

.form-group input.error,
.form-group textarea.error {
  border-color: red;
}

.form-group textarea {
  color: #000 !important; /* Força a cor do texto para preto */
}

.error-text {
  color: red;
  font-size: 14px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #2e3191;
  color: #f2e3d1;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #1e2171;
}