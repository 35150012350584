.home-about-section {
  padding: 90px 0;
  background-color: #f2e3d1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
}

.italic-font {
  font-style: italic;
}

.bold-font {
  font-weight: bold;
}

.large-text {
  font-size: 50px; /* Adjust the size as needed */
  color: #2E3191; /* Ensure the color matches the design */
  text-align: center;
  margin-bottom: 20px;
}

.about-image {
  flex: 1 1 40%;
  max-width: 40%;
  margin-right: 20px;
  box-shadow: 0px 20px 60px rgba(11, 12, 48, 0.15);
  text-align: center;
}

.about-image img {
  width: 100%;
  border-radius: 2px;
}

.about-text {
  flex: 1 1 55%;
  max-width: 55%;
  text-align: left;
}

.about-text h2 {
  color: #2E3191;
  font-size: 40px;
  margin-bottom: 50px;
}

.about-text p {
  margin-bottom: 21px;
  font-size: 18px;
  line-height: 1.6;
  color: #4c4f7e;
}

.about-buttons {
  display: flex;
  gap: 20px;
  margin-top: 70px;
}

.download-poster-btn,
.inscriptions-btn {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  background: #2E3191;
  background-size: 200% 200%;
  transition: background-position 0.5s, transform 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.download-poster-btn:hover,
.inscriptions-btn:hover {
  background-position: right center;
  transform: scale(1.05);
}

.italic-font {
  font-family: 'SourceSerif4Italic', serif;
  font-style: italic;
}

.future-title {
  display: inline-block;
  margin-left: 130px;
  font-weight: bold;
  font-size: 32px;
}

.highlight-coimbra {
  font-family: 'SourceSerif4Italic', serif;
  color: #2E3191;
  font-style: italic;
  font-size: x-large;
}

.motion-characters {
  display: inline-block;
  animation: motionEffect 5s infinite; /* Slowed down the animation to 4 seconds */
}

@keyframes motionEffect {
  0% { opacity: 0; transform: translateX(-10px); }
  25% { opacity: 1; transform: translateX(0); }
  50% { opacity: 0; transform: translateX(10px); }
  75% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(-10px); }
}