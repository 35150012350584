/* src/components/css/Header.css */

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2e3d1; /* Updated background color */
  padding: 20px;
}

.header-section.header-normal {
  border-bottom: none; /* Remove bottom border */
}

.logo {
  padding: 10px 20px;

}

.logo img {
  width: 40%;
}

.logo a {
  display: inline-block;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-menu .mainmenu {
  display: flex;
  align-items: center;
}

.nav-menu .mainmenu ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu .mainmenu ul li {
  margin-right: 40px;
  position: relative;
}

.nav-menu .mainmenu ul li a {
  font-size: 16px;
  color: #e55041; 
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 0;
  text-decoration: none;
  position: relative;
}

.nav-menu .mainmenu ul li a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  content: "";
 background-color: #2e3191; 
  opacity: 0;
  transition: all 0.3s;
}

.nav-menu .mainmenu ul li:hover>a:after {
  opacity: 1;
}

.nav-menu .primary-btn.top-btn {
  margin: 15px 0;

  color:  #f2e3d1;
  border-radius: 50px;
  border: 2px solid transparent;
  padding: 10px 20px;
  font-weight: 600;
  transition: all 0.3s;
  text-align: center;
  text-transform: uppercase;
}

.nav-menu .primary-btn.top-btn:hover {
  color: #f2e3d1; /* Updated text color */
	background-color: #1d1f6b; /* Slightly darker shade for hover */
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu span {
  height: 3px;
  width: 25px;
  background-color: #e55041;
  margin: 4px 0;
  transition: all 0.3s;
}

@media (max-width: 960px) {
  .nav-menu .mainmenu {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #f2e3d1; /* Updated background color */
    position: absolute;
    top: 90px;
    left: 0;
    height: 100vh;
    z-index: 1000;
    padding: 20px;
    transition: all 0.3s;
    text-align: center;
  }

  .nav-menu .mainmenu.active {
    display: flex;
  }

  .nav-menu .mainmenu ul {
    flex-direction: column;
  }

  .nav-menu .mainmenu ul li {
    margin-right: 0;
    margin-bottom: 50px; /* Add space between menu items */
  }

  .nav-menu .primary-btn.top-btn {
    margin: 10px 0;
  }

  .hamburger-menu {
    display: flex;
  }
}