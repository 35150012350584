.hotel-section {
  padding: 60px 0;
  background-color: #f2e3d1;
  text-align: center;
}

.section-title {
  margin-bottom: 50px;
}

.section-title h2 {
  font-size: 36px;
  font-weight: 700;
  color: #2e3191;
}

.hotel-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.hotel-card {
  background: #ffffffaf;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
  transition: transform 0.3s;
}

.hotel-card:hover {
  transform: scale(1.05);
}

.hotel-card h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2e3191;
}

.hotel-card p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.hotel-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
}

.highlight {
  color: #e55041;
  font-weight: bold;
}

.discount {
  background-color: #e55041;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
  color: #fff; /* Define the color for the text inside the discount paragraph */
}

.hotel-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
}