/* Global styles for the timeline container */
.timeline-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 0;
  font-family: Arial, sans-serif;
  text-align: left;
}

.timeline-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #2E3191; /* Cor secundária */
}

.timeline {
  position: relative;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  width: 2px;
  background-color: #e54f41; /* Linha vertical */
}

.timeline-item {
  position: relative;
  margin-bottom: 40px;
  padding-left: 40px;
}

.timeline-dot {
  position: absolute;
  top: 0;
  left: 11px;
  width: 18px;
  height: 18px;
  background-color: #e54f41; /* Ponto na linha */
  border-radius: 50%;
}

.timeline-content {
  margin-left: 40px;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.timeline-date {
  font-weight: bold;
  color: #000000; /* Cor principal */
  margin-bottom: 10px;
  display: block;
  font-size: large;
}

.timeline-content p {
  margin: 0;
  font-size: 16px;
  color: #524c4c;
}

@media (max-width: 768px) {
  .timeline-container {
    padding: 20px;
  }

  .timeline-item {
    padding-left: 30px;
  }

  .timeline-dot {
    left: 6px;
  }

  .timeline-content {
    margin-left: 30px;
  }
}
.timeline-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #2e3191; /* Cor oficial azul */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.timeline-button:hover {
  background-color: #e55041; /* Cor oficial vermelha */
 color:#fff;
}

