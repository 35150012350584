/* src/components/css/Sponsors.css */
.sponsors-section {
  background-color: #e55041;
  padding: 5px 0;
  text-align: center;
}

.sponsors-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow logos to wrap to the next line */
  gap: 50px;
  padding: 10px; /* Add some padding for better appearance */
}

.sponsor-logo {
  flex: 1 1 200px; /* Allow logos to grow and shrink */
  max-width: 200px;
  margin: 10px; /* Add margin for better spacing */
}

.sponsor-logo img {
  width: 100%;
  height: auto;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .sponsor-logo {
    flex: 1 1 100px; /* Adjust size for smaller screens */
    max-width: 100px;
  }
}