.sponsors-page-section {
    padding: 60px 0;
    background-color: #f2e3d1;
    text-align: center;
  }
  
  .sponsors-page-section-title {
    margin-bottom: 50px;
  }
  
  .sponsors-page-section-title h2 {
    font-size: 36px;
    font-weight: 700;
    color: #2e3191;
  }
  
  .sponsors-page-category {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #e55041;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sponsors-page-category h3 {
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
    background-color: #e55041;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .sponsors-page-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .sponsors-page-logo {

    border-radius: 10px;
    padding: 20px;
    width: 250px;
    text-align: center;
    transition: transform 0.3s;
  }
  
  .sponsors-page-logo:hover {
    transform: scale(1.05);
  }
  
  .sponsors-page-logo img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }