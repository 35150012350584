.schedule-section {
  background-color: #f2e3d1; /* Background color */
  color: #2e3191; /* Text color */
  padding: 60px 0;
  font-family: 'Inter', sans-serif; /* Use Inter font */
}

.section-title h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.schedule-tab .nav-tabs {
  border-bottom: none;
  justify-content: center;
  margin-bottom: 30px;
}

.schedule-tab .nav-item {
  margin: 0 15px;
}

.schedule-tab .nav-link {
  color: #000000;
  background-color: transparent;
  border: none;
  font-size: 18px;
  padding: 10px 20px;
  transition: all 0.3s;
}

.schedule-tab .nav-link.active {
  color: #f2e3d1; /* Active tab text color */
  background-color: #e55041; /* Active tab background color */
  border-radius: 5px;
}

.schedule-tab .nav-link.active p {
  color: #f2e3d1; /* Active tab date color */
}

.tab-content .tab-pane {
  display: none;
}

.tab-content .tab-pane.active {
  display: block;
}

.sc-item {
  background-color: #1b1f2a44; /* Content background color */
  padding: 20px;
  border-radius: 5px;
  transition: all 0.3s;
  text-align: center; /* Center align content */
}

.sc-item img {
  width: 80px; /* Smaller size for the image */
  height: 80px; /* Set height for circular shape */
  border-radius: 50%; /* Make image circular */
  margin-bottom: 20px;
}

.sc-item h4 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #e55041; /* Title color */
}

.sc-item ul {
  list-style: none;
  padding: 0;
}

.sc-item ul li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #ffffff; /* Text color */
}

.sc-item ul li i {
  margin-right: 10px;
  color: #e55041; /* Icon color */
}