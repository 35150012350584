/* src/components/css/Cookies.css */
.cookie-consent {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2e3191f0; /* Official color */
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
  text-align: center;
  animation: slideUp 0.5s ease-out;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.cookie-consent-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cookie-consent p {
  margin: 0 0 10px;
  font-size: 16px;
  font-family: 'Inter-Regular', sans-serif; /* Use official font */
  color: #b2b1b1;
}

.accept-button {
  background-color: #e54f41e7; /* Official color */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Inter-Regular', sans-serif; /* Use official font */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.accept-button:hover {
  background-color: #d43d2f; /* Slightly darker shade */
  transform: scale(1.05);
}

/* Existing styles... */

.settings-button {
  background-color: #512833; /* Green */
  color:  #f2e3d1;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Inter-Regular', sans-serif; /* Use official font */
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
}

.settings-button:hover {
  background-color:#512833; /* Official color */
  transform: scale(1.05);
}

.cookie-settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2f2d2d;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.cookie-settings-modal h2 {
  margin-bottom: 20px;
}

.cookie-settings-modal label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: 'Inter-Regular', sans-serif; /* Use official font */
}

.save-button {
  background-color:#d43d2f; ; /* Green */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Inter-Regular', sans-serif; /* Use official font */
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
}

.save-button:hover {
  background-color: #e55041; 
  transform: scale(1.05);
}