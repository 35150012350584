/* Speakers.css */

.speaker-section {
  padding: 60px 0;
  background-color: #f2e3d1;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
  margin-right: 50px;
}

.section-title h2 {
  font-size: 36px;
  color: #2e3191;
  font-weight: 700;
}

.section-title p {
  font-size: 16px;
  color: #555;
}

.speaker-item {
  margin-bottom: 30px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
}

.si-pic {
  position: relative;
  width: 100%;
  height: 300px; /* Set a fixed height for the images */
  overflow: hidden;
}

.si-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire area */
  border-radius: 10px 10px 0 0;
}

.si-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
}

.si-text h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
}

.si-social {
  text-align: center;
  padding: 10px;
}

.si-social a {
  color: #0077b5;
  font-size: 18px;
  margin-right: 10px;
  transition: color 0.3s;
}

.si-social a:hover {
  color: #005582;
}