.exhibition-page {
  padding: 40px 0;
  background-color: #f2e3d1;
}

.exhibition-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.exhibition-title {
  text-align: center;
  font-size: 36px;
  color: #2e3191;
  font-weight: 700;
  margin-bottom: 40px;
}

.exhibition-swiper-container {
  width: 100%;
  height: 600px;
  margin-bottom: 40px;
}

.exhibition-swiper-slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.exhibition-swiper-slide-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.exhibition-swiper-slide-content h3 {
  margin-top: 10px;
  font-size: 1.5rem;
  color: #2e3191;
}

.exhibition-content {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.exhibition-content p {
  margin-bottom: 20px;
}

.exhibition-content ol {
  margin-bottom: 20px;
  padding-left: 20px;
}

.exhibition-content ol li {
  margin-bottom: 10px;
}