
/* src/App.css */
/* Update font-face */
@font-face {
  font-family: 'Inter-Regular';
  src: url('../public/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../public/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'SourceSerif4-SemiBoldItalic';
  src: url('../public/fonts/SourceSerif4-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Global styles */
* {
  font-family: 'Inter-Regular', sans-serif; /* Primary font */

/*   border: 2px solid red; */
}


html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #F2E3D1
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #2E3191; /* Primary color */
  font-weight: 400;
  font-family: 'SourceSerif4-SemiBoldItalic', serif; /* Secondary font */
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}
p {
  font-size: 16px;
  color: #6a6b7c;
  font-weight: 400;
  line-height: 28px;
  margin: 0 0 15px 0;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: #E55041; /* Secondary color */
}

ul,
ol {
  padding: 0;
  margin: 0;
}

.section-title {
  text-align: center;
  margin-bottom: 52px;
}

.section-title h2 {
  font-size: 40px;
  font-weight: 700;
  color: #2E3191; /* Primary color */
  margin-bottom: 10px;
}

.section-title p {
  font-size: 18px;
  color: #6a6b7c;
  margin-bottom: 0;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.spad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.primary-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 40px;
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  background-color: #E55041; /* Secondary color */
  transition: all 0.3s;
}

.roller-text-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: transparent; /* Primary color */
  color: #2e3191 ;
}

.Home {
  background-color: #F2E3D1;
}

/* 
Pages  */
/* Atualização do estilo da página de Termos de Utilização */
.body-background {
  background-color: #F2E3D1; /* Same background color as .app-container */
}
.app-container {
  padding: 60px 20px; /* Mais espaço ao redor do conteúdo */
  background-color: #F2E3D1; /* Fundo branco para um visual mais limpo */
  font-family: 'Inter-Regular', sans-serif;
  color: #333; /* Texto cinza-escuro para legibilidade */
  max-width: 1000px; /* Limitar a largura para um texto mais fácil de ler */
  margin: 0 auto; /* Centralizar conteúdo na página */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adicionar uma sombra suave */
}

.app-title {
  font-size: 3em; /* Aumentar o tamanho da fonte */
  margin-bottom: 30px;
  color: #272969; /* Cor oficial */
  font-family: 'SourceSerif4-SemiBoldItalic', serif; /* Fonte secundária */
  text-align: center; /* Centralizar o título */
}

.app-paragraph {
  font-size: 1.2em; /* Aumentar um pouco o tamanho da fonte */
  line-height: 1.8; /* Melhorar o espaçamento entre linhas */
  margin-bottom: 30px;
  text-align: justify; /* Alinhar o texto justificado */
}

.app-subtitle {
  font-size: 2em; /* Tamanho maior para subtítulos */
  margin-top: 40px;
  margin-bottom: 20px;
  color: #2E3191; /* Cor oficial */
  font-family: 'SourceSerif4-SemiBoldItalic', serif; /* Fonte secundária */
}

.app-list {
  margin-left: 20px;
  color: #333;
}

.app-list-item {
  margin-bottom: 20px; /* Mais espaço entre os itens */
  font-size: 1.2em; /* Aumentar o tamanho da fonte */
  line-height: 1.8;
}

.app-list-item strong {
  color: #2E3191; /* Cor oficial */
}
