.footer {
  background-color: #e55041;
  color: #f2e3d1;
  padding: 40px 0;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer-column {
  margin-bottom: 20px;
}

.footer-column.small-column {
  flex: 1;
  min-width: 200px;
}

.footer-column.large-column {
  flex: 2;
  min-width: 300px;
}

.footer-column h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #f2e3d1;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color:#f2e3d1;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-column ul li a:hover {
  color: #2e3191;
}

.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.footer-social a {
  color: #f2e3d1;
  margin: 0 15px;
  font-size: 40px; /* Aumenta o tamanho dos ícones */
  transition: color 0.3s;
}

.footer-social a:hover {
  color: #2e3191;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top button {
  background-color: #2e3191;
  border: none;
  color: #f2e3d1;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.back-to-top button:hover {
  background-color: #f2e3d1;
  color: #2e3191;
}

.footer-bottom {
  text-align: center;
  font-size: 14px;
  color: #2e3191;
}

/* ContactForm.css */
.contact-form-container {
  background-color: transparent;
  padding: 0;
}

.contact-form-container h2 {
  display: none; /* Hide the Contact Us heading */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #f2e3d1;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f2e3d1;
  color: #f2e3d1;
}

.form-group input.error,
.form-group textarea.error {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 14px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #2e3191;
  color: #f2e3d1;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #f2e3d1;
  color: #2e3191;
}